import React,{ useState } from "react";
import {Label,Input, FormGroup,Button, Row, Col} from "reactstrap";
import ModalArticle from "../ModalArticle";
const htmlparser2 = require("htmlparser2");

export default function ListSetValues({
  page, setPage, markings, deleteObject, categories
}) {
  const [modal, setModal] = useState(false);

    //add o título no objeto da página
    const onChangeTitle = (id, title) => {
   // const { page, setPage } = this.props;
    page.elements.forEach(v => {
      if (v.id === id) {
        v.title = title;
      }
    });
    setPage(page)
  }

  //add o tipo no objeto da página
    const onChangeSelect = id => e => {
    //const { page,setPage } = this.props;
    page.elements.forEach(v => {
      if (v.id === id) {
        v.type = e.target.value;
      }
    });

    if(e.target.value !== "selecione"){
      setModal(!modal)
    } else {
      setModal(false)
    }
    setPage(page)
  }

    //add o valor(caso tenha escolhido pagina) no objeto para page
    const onChangeValue = id => e => {
        //const { page, setPage } = this.props;
        page.elements.forEach(v => {
          if (v.id === id) {
            v.value = e.target.value;
          }
        });
        setPage(page)
      }

        //add o valor(caso tenha escolhido artigo) no objeto para page
  const onChangeValueArticle = (article, id, type) => {
   // const { page, setPage } = this.props;
    page.elements.forEach(v => {
      if (v.id === id) {
        v.value = article;
        v.typeArticle = type;
      }
    });
    //setando esse valor para page
    setPage(page)
  };

  const toggle = () => {
    setModal(modal === true ? false : true)
  }

  const parseHtml = html => {
    let textParsed;

    const parser = new htmlparser2.Parser(
      {
        ontext(text) {
          textParsed += text;
        }
      },
      { decodeEntities: true }
    );

    parser.write(html);
    parser.end();

    return textParsed;
  };

      const list = [{name: "Selecione", value:"selecione"}, {name: "artigo", value:"artigo"}]
        return (
        <>
            <h5>Elementos</h5>
            <hr />
            <div className="mw-m-b-30 mw-pad-l-0">
                {page && page.elements.map((v,i) => {
                    return (
                        <div key={v.id} >
                            {/* Adicionando os títulos */}
                             {markings && markings.elements.map(({title, type, id}) => {
                              return id === v.id &&                          
                              <div key={id+title}>
                                  <Label >Título: {title ? title : "Não Definido"}</Label><br/>
                                  <Label >Tipo: {type ? type : "Não Definido"} </Label> 
                                  <br/>
                              </div>
                            } ) }
                            {/* Adicionando o selection e botão de apagar */}
                            <FormGroup>
                              <Row>
                                <Col xs='7'>
                                <Label for="exampleSelect">Escolher</Label>
                                {/*onChangeSelect: add o tipo no objeto da página */}
                                <Input
                                    type="select"
                                    name="select"
                                    id="type"
                                    //onClick={imprime}
                                    onClick={onChangeSelect(v.id)}
                                >
                                  {list.map(v => 
                                    <option key={v.name} value={v.value}>{v.name}</option>
                                  )}
                                    
                                </Input>
                                </Col>
                                <Col xs='5' style={{display: 'flex', alignItems: 'flex-end'}}>
                                <Button onClick={() => deleteObject(v.id)} color="danger" className="gza-btn">
                                Apagar
                              </Button>
                              </Col>
                                </Row>
                            </FormGroup>

                            {v.type === "artigo" && (
                                <>
                                  <ModalArticle
                                      id={v.id} //id do objeto
                                      active={modal} //estado da modal
                                      toggle={toggle} //função de trocal state do modal
                                      changeValueArticle={onChangeValueArticle} //função para setar valor e tipo do artigo
                                      changeTitle={onChangeTitle} //função para setar o title
                                      parseHtml={parseHtml} //conversor do html
                                      categories={categories} //categorias
                                  />
                                </>
                            )}
                        <hr />
                        </div>
                    );
                })}
            </div>
        </>
    );
}