import React from "react";
import { Container, Row, CardTitle, Col } from "reactstrap";
import { baseUrlDPA } from "../utils/url";
import { Redirect, Link } from "react-router-dom";
import Header from "./Header";

/*Classe home, responsável por mostrar todas as edições disponíveis */
export default class Home extends React.Component {
  state = {
    redirect: false /*Variável para redirecionar para outras telas */
  };

  /*Setando o valor do redirect */
  setRedirect() {
    this.setState({ redirect: true });
  }

  render() {
     /*Informações vindas do component APP:
     editions: todas as edições disponíveis
     selectEdition: função vinda de app para pegar a edição escolhida
     */
    const { editions, selectEdition } = this.props;
    return (
      <>
        <Header />
        <Container className="mw-pad-t-30 mw-m-t-50">
          {/*Se redirect for true, redireciona para o componente pages */}
          {this.state.redirect && <Redirect to="/pages" />}

          {/*Renderizando todas as edições na tela */}
          <Col md="12" className="mw-pad-0">
            <Row>
              {editions.map(v=> (
                <Col sm="3" className="mw-m-b-15" key={v.editionId}>
                  <Col
                    md="12"
                    className="mw-pad-0"
                    onClick={() => {
                      selectEdition(v);
                      this.setRedirect();
                    }}>
                    <Link to="/pages">
                      <figure
                        className="col-12 mw-pad-0 dol-h-390 mw-wrapper"
                        style={{ border: "1px solid #eee" }}
                      >
                        {/*Exibindo imagem da edição */}
                        <img
                          className="mw-fit-width mw-fit-height mw-object-fit mw-object-top"
                          width="100%"
                          src={`${baseUrlDPA}/${v.coverPath}`}
                          alt="Card image cap"
                        />{" "}
                      </figure>
                      <div
                        className="col-12 mw-pad-15 mw-bottom-absolute"
                        style={{ background: "#eee" }}
                      >
                        <CardTitle className="mw-m-b-0 dol-c-gray-2">
                          {/*Exibindo o número da edição */}
                          <span className="dol-f-12 dol-c-white dpa-bg-red mw-b-radius-5 mw-pad-5">
                            Edição: {v.editionId}
                          </span>
                          <br /> 
                          {/*Exibindo o nome da edição */}
                          <p className="dol-f-14 mw-m-0 mw-pad-t-5">Nome: {v.name_}</p>
                          <p className="dol-f-14 mw-m-0">Data: {v.date_.split('T')[0]}</p>
                        </CardTitle>{" "}
                      </div>
                    </Link>
                  </Col>
                </Col>
              ))}
            </Row>
          </Col>
        </Container>
      </>
    );
  }
}
