import React from 'react'
import {
    Container,Col,
   Form, FormGroup, Input, Button
  } from 'reactstrap';
import Header from "./Header";
import Footer from "./Footer";
import {userGZA, passwordGZA} from "../utils/login"


export default class Login extends React.Component{
    state={
        user:null,
        password: null
    }

    handleUser = e => {
        this.setState({
          user: e.target.value
        });
      };

    handlePassword = e => {
        this.setState({
            password: e.target.value
        });
    };

    verify = () => {
        const {login} = this.props
        const {user, password} = this.state
        if(userGZA ===  user && passwordGZA == password){
            login()
        } else {
            alert("Credenciais Inválidas")
        }
    }
    
    render(){

        return(
            <>
                <Header />
                <div className="col-md-12 mw-flex mw-flex-justify-center mw-a-items-center" style={{height: '80vh', width:'100%',}}>
                    <Container 
                    className="col-md-3 mw-pad-0"
                    style={{marginTop: '80px', marginBottom: "20px", border: '2px solid #000'}}>
                        <Col sm="12" style={{textAlign: 'center', paddingBottom: '10px'}}>
                            <h1 className="dol-f-28 dol-c-black mw-pad-t-10">Seja Bem-Vindo</h1>
                            <p className="dol-f-20 dol-c-black mw-pad-t-5">Realize seu Login</p>
                            <Form>
                            <FormGroup className="col-md-12">
                            <Input
                            
                            style={{backgroundColor:"#fff",padding: '10px', margin: '0px'}}
                            type="text"
                            name="user"
                            id="user"
                            onChange={this.handleUser}
                            placeholder="Digite seu usuário"
                            />
                            <Input
                            style={{padding: '10px', marginTop: '10px', marginBottom: '10px'}}
                            type="password"
                            name="password"
                            id="password"
                            onChange={this.handlePassword}
                            placeholder="Digite seu senha"
                            />
                            <Button className="col-md-12 dpa-bg-red dol-b-none dol-c-white mw-pad-l-15 mw-pad-r-15"
                            onClick={this.verify}
                            >Entrar</Button>
                            </FormGroup>
                            </Form>
                        </Col>
                    </Container>
                    </div>
                
                {/* <Footer /> */}
            </>
        )
    }
}