import React from "react";
import { Card, CardTitle, CardText } from "reactstrap";
import { Link } from "react-router-dom";
import { baseUrlDPA } from "../../utils/url";

export default function CardPage({
  selectPage,
  setRedirect,
  number,
  image,
  section
}) {
  return (
    <Card
      body
      style={{ margin: "15px", cursor: "pointer" }}
      onClick={() => {
        selectPage(image, number);
        setRedirect();
      }}
    >
      <Link
        to="/editor"
        style={{
          width: "100%",
          height: "100%",
          padding: 10,
          textDecoration: "none"
        }}
      >
        <div>
          <img width="100%" src={`${baseUrlDPA}${image}`} alt="" />
        </div>
        <div className="dol-c-gray-2 mw-pad-10" style={{ background: "#eee" }}>
          <CardTitle className="mw-m-0">
            <span className="dol-f-12 dol-c-white dpa-bg-red mw-b-radius-5 mw-pad-5">
              Seção: {section}
            </span>
          </CardTitle>
          <CardText className="mw-pad-t-5">Nome da Página: {number}</CardText>
        </div>
      </Link>
    </Card>
  );
}
