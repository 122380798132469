import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Axios from "axios";
import {baseUrlDPA} from "./utils/url"

import "./index.css";

/*Carregando as telas do projeto */
import Home from "./screens/Home";
import Editor from "./screens/Editor";
import Pages from "./screens/Pages";
import Login from "./screens/Login";

/*Classe principal do projeto, ela quem controla o envio de informações para as outras telas */
class App extends React.Component {
  state = {
    editions: [] /*Todas as edições irá ficar nessa variável */,
    selectEdition: {} /*Edição escolhida na home */,
    selectPage: null, //página escolhida para edição
    selectPathUrlPage: null, //url da página escolhida
    logado: false,
  };

  /*Setando o valor da edição escolhida na tela home*/
  handleEditEdition = edition => {
    this.setState({ selectEdition: edition });
  };

  /*Setando o valor da página escolhida na tela page*/
  handleEditPage = (urlPage, page) => {
    this.setState({ selectPathUrlPage: urlPage });
    this.setState({ selectPage: page });
  };

  setLogin = () => {
    this.setState({logado: true})
  }

  /*Carregando todas as edições existentes, no qual será mandada para a home */
  componentDidMount = async () => {
    try {
      const { data } = await Axios.get(`${baseUrlDPA}/hub/json/home`);
      this.setState({ editions: data });
    } catch (e) {
      console.log("error: ", e);
    }
  };

  render() {
    return (
      /* Funções de roteamento para as páginas */
      <Router>
        <Switch>
          {/* Rota para acessar home (raiz), enviando para ela as edições e a função para armazenar 
          a edição escolhida */}
          {this.state.logado ? 
          <>
          <Redirect to='/'/>
          <Route
            exact
            path="/"
            render={() => (
              <Home
                editions={this.state.editions}
                selectEdition={this.handleEditEdition}
              />
            )}
          />
          {/* Rota para acessar pages, enviando todas informações da edição, markingsPage irá armazenar as marcações 
          referente a página e selectPage irá trazer qual o número da página e sua respectiva foto */}
          <Route
            exact
            path="/pages"
            render={() => (
              <Pages
                edition={this.state.selectEdition}
                selectPage={this.handleEditPage}
              />
            )}
          />

          {/* Rota para acessar editor, passando os títulos, a edição, as marcações, url da imagem e o nº da página */}
          
          <Route
            exact
            path="/editor"
            render={() => (
              <Editor
                selectEdition={this.state.selectEdition.editionId}
                selectPageId={this.state.selectPage}
                selectPathUrlPage={this.state.selectPathUrlPage}
              />
            )}
          />
          </>
           : 
          <>
          <Redirect to='/login'/>
          <Route
          exact 
          path='/login'
          render={() => (
            <Login 
              login={this.setLogin}
            />
          )}
          />
          </> 
          }
        </Switch>
      </Router>
    );
  }
}

export default App;
